<template>
    <div>
        Step {{ item.configuration.currentStep + 1 }}
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>